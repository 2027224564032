<template>
  <div class="main">
    <b-modal
      :id="refModal"
      :ref="refModal"
      :title="title"
      hide-footer
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <label
          class="labelInput"
          for="inputOriginalPrice"
        >
          Kho: {{ storeName }}
        </label>
        <b-table
          :items="listProductImei"
          :fields="fields"
          class="table-bordered table-hover col-md-12"
        >
          <template v-slot:cell(imeiCode)="row">
            <div class="d-flex justify-content-center">
              <span v-text="row.item.imeiCode"></span>
            </div>
          </template>
          <template v-slot:cell(sellingPrice)="row">
            <div>
              <span
                v-text="convertPrice(row.item.sellingPrice)"
                class="text-right"
              ></span>
            </div>
          </template>
          <template v-slot:cell(status)="row">
            <div class="d-flex justify-content-center">
              <span class="label label-light-warning font-weight-bold label-lg label-inline">
                {{PRODUCT_STATUS_NAME[row.item.status]}}
              </span>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <v-icon
                small
                class="text-danger"
                @click="applyImei(row.item)"
                v-b-tooltip
                title="Áp dụng"
              >
                mdi-pen
              </v-icon>
            </div>
          </template>
        </b-table>
        <b-button
          style="margin-left: 10px; font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
          @click="hideModal"
        >Hủy</b-button>
      </v-form>
    </b-modal>
  </div>
</template>

<script>
import { PRODUCT_STATUS_NAME } from '@/utils/enum-name';
import { EventBus } from '@/core/services/event-bus';
import { convertPrice } from '@/utils/common';

export default {
  data() {
    return {
      PRODUCT_STATUS_NAME,
      refModal: 'list-imei-modal',
      fields: [
        {
          key: 'imeiCode',
          label: 'IMEI',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        { key: 'actions', label: '' },
      ],
    };
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    storeName: {
      type: String,
      default: () => '',
    },
    listProductImei: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    convertPrice,
    hideModal() {
      this.$bvModal.hide(this.refModal);
    },
    applyImei(data) {
      EventBus.$emit('applyImei', data);
      this.hideModal();
    },
  },
};
</script>
